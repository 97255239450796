
@font-face {
    font-family: "DSEG7Classic-Bold";
    font-style: normal;
    font-weight: bold;
    src: url("../node_modules/dseg/fonts/DSEG7-Classic/DSEG7Classic-Bold.woff2");
}

@font-face {
    font-family: "DSEG14Classic-Bold";
    font-style: normal;
    font-weight: bold;
    src: url("../node_modules/dseg/fonts/DSEG14-Classic/DSEG14Classic-Bold.woff2");
}

@font-face {
    font-family: "DSEGWeather";
    font-style: normal;
    font-weight: bold;
    src: url("../node_modules/dseg/fonts/DSEGWeather/DSEGWeather.woff2");
}
     
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

body {
    background: no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: white;
    text-shadow: 0px 0px 20px #242424;
}

.wrapper {
    min-width: 630px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-gap: 10px;
    width: 100vw;
    height: 100vh;
    font-family: Arial, Helvetica, sans-serif;
}

.bitcoin {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 10px;
    padding-left: 10px;
}

.bitcoin_data {
    padding-top: 4px;
    padding-left: 10px;
    font-family: "DSEG14Classic-Bold";
    font-size: 14px;
}

.bitcoin_current {
    padding-top: 5px;
    font-size: 20px;
}

.f1 {
    justify-self: center;
    text-align: center;
    grid-column: 2 / 5;
    grid-row: 1 / 2;
}

.f1_standings {
    display: inline-block;
}

.weather {
    grid-column: 5 / 6;
    grid-row: 1 / 2;
}

.weather_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
}

.weather_icons {
    font-family: "DSEGWeather";
    font-size: 80px;
}

.weather_temp {
    padding-left: 10px;
    font-family: "DSEG14Classic-Bold";
    font-size: 30px;
}

.weather_degree {
    align-self: flex-start;
    padding-top: 28px;
    font-family: "DSEG14Classic-Bold";
    font-size: 16px;
}

.weather_location {
    text-align: right;
    font-family: "DSEG14Classic-Bold";
    padding-top: 10px;
    padding-right: 10px;
}

.ramentimer {
    display: flex;
    grid-row: 4 / 6;
    grid-column: 5 / 6;
}

.ramentimer_bunny {
    align-self: flex-end;
    position: relative;
    width: 210px;
    height: 250px;
    margin: 15px auto;
    background-image: url("./img/Bunny_Ramen_Timer_Transparent.png");
    background-size: cover;
}

.ramentimer_display{
    position: absolute;
    top: 125px;
    left: 75px;
    color: black;
    font-size: 18px;
    font-family: "DSEG7Classic-Bold";
}

.ramentimer button {
    position: absolute;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.ramentimer button:hover {
    background-color: rgba(223, 18, 172, 0.486);
}

.ramentimer_minute-btn {
    top: 180px;
    left: 46px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
}

.ramentimer_second-btn {
    top: 179px;
    left: 87px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
}

.ramentimer_start-btn {
    top: 176px;
    left: 130px;
    width: 38px;
    height: 38px;
    border-radius: 19px;
}

.ramentimer_reset-btn {
    top: 216px;
    left: 40px;
    width: 90px;
    height: 24px;
    border-radius: 12px;
}

.time {
    align-self: center;
    text-align: center;
    grid-column: 2 / 5;
    grid-row: 3;
}

.background_controller {
    align-self: flex-end;
    grid-column: 1 / 3;
    grid-row: 5;
}

.background_author {
    margin: 8px 4px;
    color: pink;
}

.background_theme {
    font-size: 13px;
    margin: 8px 4px 0;
    opacity: 0.6;
}

.background_form input[type=text] {
    width: 150px;
    padding: 5px 10px;
    margin-left: 4px;
    margin-right: 4px;
    border: 2px solid pink;
    border-radius: 4px;
    opacity: 0.6;
}

.background_form button {
    background-color: violet;
    color: white;
    padding: 5px 10px;
    margin: 8px 0;
    border: 2px solid pink;
    border-radius: 4px;
    opacity: 0.6;
    text-decoration: none;
    cursor: pointer;
}
